<template>
  <div v-if="isLoaded">
    <!--begin::Notice-->
    <b-jumbotron
      v-if="!company.has_paid_subscription"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Sie müssen ein kostenpflichtes Abonement abschliessen um ihre BKP Nummer
        zu erfassen. Das Erfassen von einer BKP Nummer ist im Grundpreis des
        Jahresabonement enthalten. Sie können jedoch weitere BKP Nummern als
        Option hinzufügen.
      </template>
      <!--      <hr class="my-4" />-->
      <!--      <p>-->
      <!--        Sie können jedoch weitere Optionen jederzeit zu Ihrem Abonoment-->
      <!--        hinzufügen.-->
      <!--      </p>-->
      <router-link
        to="/adjust-subscription"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Abonement abschliessen</b-button
        >
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->

    <!--begin::Card-->
    <div class="card card-custom" v-else>
      <!-- begin::Form-->
      <b-form class="form" v-on:submit.prevent="save">
        <!--begin::Body-->
        <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <h4 class="mb-10 mt-10 font-weight-bold text-dark">
                Dienstleistungen
              </h4>
              <div class="row">
                <div class="col-xl-6">
                  <ValidatedInput
                    name="Standort Umkreis"
                    v-model="company.location_scope"
                    :v="$v.company.location_scope"
                    errorMessage="Das ist ein Pflichtfeld."
                    helpText="Maximale Distanz in km von Ihrem Standort aus."
                  />
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Nur Business to Business Kunden</label>
                    <b-form-radio-group
                      class="pt-4 pb-4"
                      v-model="company.only_b2b"
                      :options="b2bOptions"
                    >
                    </b-form-radio-group>
                    <b-form-text
                      >Nur registrierte Benutzer sehen ihre Profil.</b-form-text
                    >
                  </div>
                </div>
              </div>
              <h4 class="mb-10 mt-10 font-weight-bold text-dark">
                BKP Nummern
              </h4>
              <div class="row">
                <div
                  class="col-xl-6"
                  v-for="(v, index) in $v.selectedBkpNumber.$each.$iter"
                  :key="index"
                >
                  <div class="form-group">
                    <label>BKP Nr. {{ parseInt(index) + 1 }}</label>
                    <b-form-input
                      class="form-control form-control-lg form-control-solid"
                      list="bkp-list-id"
                      placeholder="BKP"
                      v-model="v.input.$model"
                      :state="validateBkpState(v)"
                      aria-describedby="input-bkp-feedback"
                    ></b-form-input>
                    <datalist id="bkp-list-id">
                      <option
                        v-for="bkpItem in getBKPNumbers"
                        v-bind:key="bkpItem.id"
                        >{{ formatBkp(bkpItem) }}
                      </option>
                    </datalist>
                    <b-form-invalid-feedback id="input-bkp-feedback">
                      Das ist keine gültige BKP Nummer.
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <!--begin: Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2"></div>
                <div>
                  <button
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Speichern
                  </button>
                </div>
              </div>
              <!--end: Actions -->
            </div>
          </div>
        </div>
        <!--end::Body-->
      </b-form>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";
import { alertMixin } from "@/mixins/alertMixin";
import ValidatedInput from "@/components/ValidatedInput";
import { LOAD_BKP_NUMBERS } from "@/store/bkpnumbers.module";
import { LOAD_USER_COMPANY, UPDATE_USER_COMPANY } from "@/store/company.module";

function bkpNumberValidation(value) {
  return this.checkBkpNumber(value);
}
export default {
  components: { ValidatedInput },
  mixins: [validationMixin, alertMixin],
  name: "CompanyServices",
  data() {
    return {
      company: {},
      isLoaded: false,
      b2bOptions: [
        { text: "Ja", value: true },
        { text: "Nein", value: false }
      ],
      selectedBkpNumber: []
    };
  },
  validations: {
    company: {
      location_scope: {
        required,
        numeric
      }
    },
    selectedBkpNumber: {
      $each: {
        input: {
          // required,
          bkpNumberValidation
        }
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      // Load first the BKP numbers before the company is loaded
      await Promise.all([
        this.$store.dispatch(LOAD_BKP_NUMBERS),
        this.$store.dispatch(LOAD_USER_COMPANY)
      ]).then(() => {
        this.company = this.getUserCompany;
        for (let i = 0; i < this.company.nr_of_bkp_numbers; i++) {
          let bkpElem = this.getBkpForId(this.company.bkp_numbers[i]);
          this.selectedBkpNumber.push({
            input: bkpElem ? this.formatBkp(bkpElem) : ""
          });
        }
      });
      this.isLoaded = true;
    },
    save() {
      this.$v.company.$touch();
      this.$v.selectedBkpNumber.$touch();
      if (this.$v.company.$anyError || this.$v.selectedBkpNumber.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      this.applySelectedBkpNumbers();

      // Always delete image -> never handled in this view
      this.$delete(this.company, "logo");

      this.$store.dispatch(UPDATE_USER_COMPANY, this.company).then(response => {
        this.company = response.data;
        this.showChangesSavedAlert();
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.company[name];
      return $dirty ? !$error : null;
    },
    validateBkpState(v) {
      const { $dirty, $error } = v;
      return $dirty ? !$error : null;
    },
    checkBkpNumber(value, isOptional = true) {
      if (isOptional && (value === null || value.length === 0)) {
        return true;
      }
      let currentValue = value.toString().toLowerCase();
      // this.bkpNumber = currentValue;
      if (currentValue.length > 0) {
        var inputValid = false;
        this.getBKPNumbers.forEach(bkp => {
          let bkpStr = this.formatBkp(bkp).toLowerCase();
          inputValid |= bkpStr === currentValue;
        });
        return inputValid;
      }
    },
    applySelectedBkpNumbers() {
      let bkpIds = [];
      this.selectedBkpNumber.forEach(item => {
        let bkpId = this.getBkpIdForName(item.input);
        if (bkpId) {
          bkpIds.push(bkpId);
        }
      });
      this.company.bkp_numbers = JSON.parse(JSON.stringify(bkpIds));
    },
    getBkpIdForName(bkpName) {
      let bkpElem = this.getBKPNumbers.find(
        bkp => bkpName === this.formatBkp(bkp)
      );
      if (bkpElem) {
        return bkpElem.id;
      }
      return null;
    },
    getBkpForId(id) {
      let bkpElem = this.getBKPNumbers.find(bkp => bkp.id === id);
      return bkpElem;
    },
    formatBkp(bkp) {
      return bkp.number + " " + bkp.working_type;
    }
  },
  computed: {
    ...mapGetters(["getBKPNumbers", "getUserCompany"])
  }
};
</script>
